var firebase = require("firebase");
var cookie = require('cookie');
var isBlank = require('is-blank');
var axios = require("axios");
import router from "../router"
import jwt_decode from 'jwt-decode';

export default {
    namespaced: true,
    state: {
        sessionInfo: {},
        fbDecodedToken: {},
        commerce: {},
        fbConnListenerSet: false,
        refreshTokenListener: null,
        internetConnected: false
    },
    mutations: {
        setSessionInfo(state, sessionInfo) {
            if (!isBlank(sessionInfo.fbToken)) {
                state.fbDecodedToken = jwt_decode(sessionInfo.fbToken)
            }
            state.sessionInfo = {
                ...state.sessionInfo,
                ...sessionInfo
            };
        },
        clearSession(state) {
            state.sessionInfo = {
                isLoggedIn: false
            }
        },
        setFbConnListenerFlag(state, flag) {
            state.fbConnListenerSet = flag
        },
        setRefreshTokenListener(state, refreshTokenListener) {
            state.refreshTokenListener = refreshTokenListener
        },
        setInternetConnected(state, internetConnected) {
            state.internetConnected = internetConnected
        },
        setCommerce(state, commerce) {
            state.commerce = commerce
        },
    },
    actions: {
        initFbToken: async function ({
            commit
        }) {
            if (firebase.apps.length === 0) {
                var config = {
                    apiKey: process.env.VUE_APP_FB_API_KEY,
                    authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
                    databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
                    storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET
                };
                firebase.initializeApp(config);
            }

            try {
                var resp = await axios.get(process.env.VUE_APP_API_URL + '/auth/token');
                var jsonData = resp.data;
                commit('setSessionInfo', {
                    isLoggedIn: true,
                    userId: jsonData.id,
                    userFullName: jsonData.name,
                    tenantId: jsonData.tenantId,
                    defaultCountryCode: jsonData.defaultContryCode,
                    fbToken: jsonData.token,
                    activeChannels: jsonData.activeChannels,
                    ui: jsonData.ui
                });

                const apiUrl = jsonData.tenantId == 'gitapelangi2' ? process.env.VUE_APP_COMMERCE_GP_API_URL : process.env.VUE_APP_COMMERCE_API_URL
                const webUrl = jsonData.tenantId == 'gitapelangi2' ? process.env.VUE_APP_COMMERCE_GP_WEB_URL : process.env.VUE_APP_COMMERCE_WEB_URL

                commit('setCommerce', {
                    apiUrl: apiUrl,
                    webUrl: webUrl
                })

                try {
                    await firebase.auth().signInWithCustomToken(jsonData.token)
                    document.cookie = cookie.serialize('fbtoken', jsonData.token)
                    // https://stackoverflow.com/questions/38350843/how-to-handle-custom-firebase-token-expiry-in-firebase-3-x-x
                    // https://firebase.google.com/docs/auth/android/custom-auth
                    //dispatch('resetFbTokenRefreshListener')
                } catch (err) {
                    console.log(err)
                }

            } catch (err) {
                console.log(err)
                commit('setSessionInfo', {
                    isLoggedIn: false
                });
                router.push("/login")
            }
        }        
    }
}