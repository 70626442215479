import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from "./plugins/vuetify"
import BootstrapVue from 'bootstrap-vue'
import VueSocketIO from 'vue-socket.io';
import SocketIO from 'socket.io-client'
import VueToast from './plugins/vuenotification'

import {
  ModalPlugin
} from 'bootstrap-vue'
import HelpersPlugin from './plugins/helpers'
import './registerServiceWorker'
// import {
//   connection
// } from '../../api/source/utils/db'

Vue.config.productionTip = false
const options = {
  path: process.env.VUE_APP_SOCKET_URL
}

Vue.use(BootstrapVue);
Vue.use(ModalPlugin)
Vue.use(HelpersPlugin)
Vue.use(new VueSocketIO({
  debug: process.NODE_ENV !== 'production',
  connection: SocketIO(options),
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_"
  }
}));

const app = new Vue({
  router,
  store,
  vuetify,
  VueToast,
  render: h => h(App)
}).$mount('#app')


router.beforeEach((to, from, next) => {
  app.$store.commit('setLoading', true)

  // Simulate request
  //setTimeout(() => {
  next();
  //}, 3000)

});

var swRegistration = {};
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async function () {
    swRegistration = await navigator.serviceWorker.register('service-worker.js')
    app.$store.commit('setswRegistration', swRegistration)
    console.log('register service worker');
  });
}

router.afterEach(() => {
  app.$store.commit('setLoading', false)
});