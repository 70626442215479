import Vue from "vue";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

Vue.use(VueToast);

var app = new Vue({
  methods: {
    showToast(type, message) {
      switch (type) {
        case "success":
          this.$toast.open({
            message: message,
            type: type,
            position: "top",
            duration: 5000,
          });
          break;
        case "info":
          this.$toast.open({
            message: message,
            type: type,
            position: "top",
            duration: 7000,
          });
          break;
        case "warning":
          this.$toast.open({
            message: message,
            type: type,
            position: "bottom",
            duration: 7000,
          });
          break;
        case "error":
          this.$toast.open({
            message: message,
            type: type,
            position: "top",
            duration: 1000 * 60 * 60,
            dismissible: true,
          });
          break;
      }
    },
  },
});

export default app;
