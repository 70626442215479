var axios = require("axios");

export default {
    namespaced: true,
    state: {
        labels: [],
        customerRoles: []
    },
    mutations: {
        setLabels(state, labels) {
            state.labels = labels
        },
        setCustomerRoles(state, customerRoles) {
            state.customerRoles = customerRoles
        }
    },
    actions: {
        actGetLabel: async function ({ commit, state }) {
            if(state.labels.length == 0) {
                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/label', {
                        params: {
                            page: 1,
                            size: 1000
                        }
                    })

                    let tmp = data.content.map(e => e.name)

                    commit('setLabels', tmp)
                } catch (error) {
                    console.log("error get label", error);
                }
            }
        },
        actGetCustomerRoles: async function ({ commit, state }) {
            if(state.customerRoles.length == 0) {
                try {
                    const { data } = await axios.get(process.env.VUE_APP_API_URL + '/cust-role', {
                        params: {
                            page: 1,
                            size: 1000
                        }
                    })

                    commit('setCustomerRoles', data.content)
                } catch (error) {
                    console.log("error get customer role", error);
                }
            }
        },
    },
}