import Vue from 'vue'
import Vuex from 'vuex'
import session from './session'
import operator from './operator'
import socket from './socket'
import select from './select'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  //namespaced: true,
  state: {
    qrCode: null,
    connectedChannels: [],
    loading: false
  },
  mutations: {
    setLoading(state, bool) {
      state.loading = bool;
    },
    setQrCode(state, message) {
      state.qrCode = message.qrCode
    },
    updateWAClientSessionState(state, session) {
      console.log('updating session state: ', session)
      const foundIdx = state.connectedChannels.findIndex(e => e.sessionId == session.sessionId);
      console.log('found idx: ', foundIdx)
      if (foundIdx < 0) {
        state.connectedChannels = [session, ...state.connectedChannels]
      } else if (state.connectedChannels[foundIdx].state != session.state) {
        state.connectedChannels[foundIdx] = session
        state.connectedChannels = [...state.connectedChannels]
      }
    },
    addWAClientSession(state, session) {
      console.log('adding channle', session)
      console.log('cuyrrne session', state.connectedChannels)
      const findIdx = state.connectedChannels.findIndex(e => e.sessionId == session.sessionId);

      if (findIdx < 0) {
        state.connectedChannels = [session, ...state.connectedChannels]
      }
    },
    setWAClientSessions(state, sessions) {
      state.connectedChannels = sessions
    },
    removeWAClientSession(state, idx) {
      state.connectedChannels = [...state.connectedChannels.splice(idx, 1)]
    }
  },
  getters: {
    getLoading: state => {
      return state.loading;
    },
    getChannelState: (state, getters, rootState) => (channelId) => {
      const sessionId = rootState.session.sessionInfo.tenantId + ':' + channelId
      console.log('getting sessionId: ', sessionId)
      return state.connectedChannels.find(e => e.sessionId == sessionId)
    }
  },
  actions: {
    clearSession: ({
      commit
    }) => {
      commit('session/clearSession')
      commit('operator/clearData')
    },
    getActiveOpenWa: async ({
      commit
    }) => {
      var resp = await axios.get(process.env.VUE_APP_API_URL + '/commchannel/openwa-active/')
      commit('setWAClientSessions', resp.data)
    },
    getThreadCount: async () => {
      await axios.get(process.env.VUE_APP_API_URL + '/email/threads-count-read')
    },
    getCommentCount: async () => {
      await axios.get(process.env.VUE_APP_API_URL + '/comments/count/not-reply')
    },
    // logoutOpenWASession: ({
    //   state,
    //   commit
    // }, sessionId) => {
    //   console.log('running mutasion in session id', sessionId)
    //   console.log('cuyrrne session', state.connectedChannels)
    //   const findIdx = state.connectedChannels.findIndex(e => e.sessionId == sessionId);
    //   console.log('found ixd', findIdx)
    //   if (findIdx >= 0) {
    //     commit('removeWAClientSession', findIdx);
    //   }
    // }
  },
  modules: {
    session: session,
    operator: operator,
    socket: socket,
    select: select
  }
})