export default {
    state: {
        swRegistration: null,
        unreadUnassigned: {
            unreadCount: 0
        },
        unreadMyChat: {
            unreadCount: 0
        },
        updatedChatHeader: {},
        socketMessage: null,
        activeOperatorIds: [],
        newMessage: {},
        socketConnected: false,
        countEmail: {},
        countComment: {},
        broadcastCallback: {},
        emailThreadCallback: {},
        emailThreadUpdateCallback: {},
        emailMessageCallback: {},
        newEmail: false,
        newMyEmail: false,
        newPendingEmail: false,
    },
    mutations: {
        SOCKET_CONNECT: (state, status) => {
            state.socketConnected = true;
            console.log('socket status', status)
        },
        SOCKET_DISCONNECT: (state) => {
            state.socketConnected = false;
        },
        SOCKET_CHAT_HEADER_UPDATED(state, message) {
            state.updatedChatHeader = message;
        },
        SOCKET_USER_MESSAGE: (state, message) => {
            state.socketMessage = message;
        },
        SOCKET_UNASSIGNED_UNREAD_CHAT: (state, message) => {
            if (document.visibilityState == 'hidden' && message.unreadCount > 0 && message.unreadCount != state.unreadUnassigned.unreadCount && state.swRegistration) {
                state.swRegistration.showNotification('Pesan', {
                    icon: 'https://www.bippotalk.com/images/logo-login-icon.png',
                    body: "Terdapat " + message.unreadCount + " pesan baru yang belum ditangani"
                });
            }
            state.unreadUnassigned = message;
        },
        SOCKET_MY_UNREAD_CHAT: (state, message) => {
            if (document.visibilityState == 'hidden' && message.unreadCount > 0 && message.unreadCount != state.unreadMyChat.unreadCount && state.swRegistration) {
                console.log('sending notification');
                state.swRegistration.showNotification('Balasan dari Pelanggan', {
                    icon: 'https://www.bippotalk.com/images/logo-login-icon.png',
                    body: "Terdapat " + message.unreadCount + " balasan dari pelanggan yang belum dibaca"
                });
            }
            state.unreadMyChat = message;
        },
        SOCKET_EMAIL_THREAD_NOTIFICATION: (state, message) => {
            state.emailThreadCallback = message
        },
        SOCKET_EMAIL_THREAD_UPDATE_NOTIFICATION: (state, message) => {
            state.emailThreadUpdateCallback = message
        },
        SOCKET_EMAIL_MESSAGE_NOTIFICATION: (state, message) => {
            state.emailMessageCallback = message
        },
        SOCKET_BROADCAST_STATUS_UPDATE: (state, message) => {
            // console.log(message);

            state.broadcastCallback = message
            // let broadcastContactStatusCopied =  [...state.broadcastContactStatus]

            // const contactStatus = {
            //     contactSent: message.contactSent,
            //     deliveryStatus: message.deliveryStatus,
            // }
            
            // if(broadcastContactStatusCopied.length > 0) {
            //     const index = broadcastContactStatusCopied.findIndex(e => e.contactSent == message.contactSent)

            //     if(index != -1) {
            //         broadcastContactStatusCopied.splice(index, 1, contactStatus)
            //     } else {
            //         broadcastContactStatusCopied.push(contactStatus)
            //     }
            // } else {
            //     broadcastContactStatusCopied.push(contactStatus)
            // }

            // state.broadcastStatus = message.deliverySummary
            // state.broadcastContactStatus = broadcastContactStatusCopied
        },
        setswRegistration(state, swRegistration) {
            state.swRegistration = swRegistration;
        },
        setActiveOperatorIds(state, opList) {
            state.activeOperatorIds = opList
        },
        setOperatorSignIn(state, newOp) {
            state.activeOperatorIds = [...state.activeOperatorIds, ...newOp]
        },
        setNewEmail(state, newEmail) {
            state.newEmail = newEmail
        },
        setNewMyEmail(state, newMyEmail) {
            state.newMyEmail = newMyEmail
        },
        setNewPendingEmail(state, newPendingEmail) {
            state.newPendingEmail = newPendingEmail
        },
        setCountEmail(state, countEmail) {
            state.countEmail = countEmail
        },
        setCountComment(state, countComment) {
            state.countComment = countComment
        }
    },
    actions: {
        SOCKET_OPERATOR_SIGNIN: ({commit, state, rootState}, opSign) => {
            commit('setOperatorSignIn', opSign)

            const allOperator = rootState.operator.operatorList

            let activeOperator = []
            
            // check active operator
            if(allOperator.length > 0) {
                activeOperator = allOperator.map(e => {
                    if (state.activeOperatorIds.indexOf(e._id) != -1) e.active = 1
                    return e
                });
            }

            // sort operator active to top
            activeOperator.sort((a,b) => b.active - a.active)

            commit('operator/setOperatorList', activeOperator)
        },
        SOCKET_OPERATOR_SIGNOUT: ({commit, state, rootState}, opList) => {
            var tmp = [...state.activeOperatorIds]
            for (var i = 0; i < opList.length; i++) {
                var foundIdx = tmp.findIndex(e => e == opList[i])
                if (foundIdx >= 0) tmp.splice(foundIdx, 1)
            }

            const allOperator = rootState.operator.operatorList

            let activeOperator = []
            
            // check active operator
            if(allOperator.length > 0) {
                activeOperator = allOperator.map(e => {
                    if (opList.indexOf(e._id) != -1) e.active = 0
                    return e
                });
            }

            commit('operator/setOperatorList', activeOperator)
            commit('setActiveOperatorIds', tmp)
        },
        SOCKET_ACTIVE_OPERATORS: ({commit, rootState}, opList) => {
            commit('setActiveOperatorIds', opList)

            const allOperator = rootState.operator.operatorList

            let activeOperator = []
            
            // check active operator
            if(allOperator.length > 0) {
                activeOperator = allOperator.map(e => {
                    if (opList.indexOf(e._id) != -1) e.active = 1
                    return e
                });
            }

            // sort operator active to top
            activeOperator.sort((a,b) => b.active - a.active)

            commit('operator/setOperatorList', activeOperator)

        },
        SOCKET_NEW_MESSAGE({state, rootState}, message) {
            if (document.visibilityState == 'hidden' && state.swRegistration) {
                let chatBody = String()
                let title = rootState.session.sessionInfo.userId == state.updatedChatHeader.currentOperatorId ? `${message.author.name} membalas pesan` : `Pesan baru dari ${message.author.name}`

                if (message.contentType == 'text') {
                    if (message.text.length > 200) {
                        chatBody = message.text.substring(1, 200)
                    } else {
                        chatBody = message.text
                    }
                }

                if (message.contentType == 'image') chatBody = 'mengirimkan foto'
                if (message.contentType == 'video') chatBody = 'mengirimkan video'
                if (message.contentType == 'document') chatBody = 'mengirimkan file'

                state.swRegistration.showNotification(title, {
                    icon: 'https://www.bippotalk.com/images/logo-login-icon.png',
                    body: chatBody
                });
            }
        },
        SOCKET_EMAIL_THREAD_NEW: ({ commit }, payload) => {
            if(payload.operatorId) {
                if(payload.status == 'open') {
                    commit('setNewMyEmail', true)
                } else if(payload.status == 'pending') {
                    commit('setNewPendingEmail', true)
                }
            } else {
                commit('setNewEmail', true)
            }
        },
        SOCKET_EMAIL_THREAD_COUNT: ({ commit, state, rootState }, payload) => {
            const count = {
                unassigned: state.countEmail.unassigned || 0,
                assigned: state.countEmail.assigned || 0,
                'all-email': state.countEmail['all-email'] || 0,
                pending: state.countEmail.pending || 0
            }
            
            if(payload && payload.count) {
                for (const property in payload.count) {
                    if(property == 'assigned' && rootState.session.sessionInfo.userId == payload.operatorId) {
                        count[property] = payload.count[property]
                    }
                    
                    if(property != 'assigned') {
                        count[property] = payload.count[property]
                    }
                }
            }

            if(rootState.session.sessionInfo.userId == payload.operatorId && !payload.count.assigned) count['assigned'] = 0
            if(rootState.session.sessionInfo.userId == payload.operatorId && !payload.count.unassigned) count['unassigned'] = 0

            commit('setCountEmail', count)
        },
        SOCKET_COMMENT_NOT_REPLY_COUNT: ({ commit }, payload) => {
            const count = {
                fb: 0,
                ig: 0
            }

            if(payload && payload.length > 0) {
                payload.forEach(e => {
                    if(e._id == 'FB') count.fb = e.count
                    if(e._id == 'IG') count.ig = e.count
                });
            }
            
            commit('setCountComment', count)
        },
        SOCKET_WA_QR: ({
            commit
        }, message) => {
            console.log('qr code called with message', message)
            commit('setQrCode', message)
        },
        SOCKET_WA_STATE_CHANGED: ({
            commit
        }, payload) => {
            commit('updateWAClientSessionState', payload)
        }
    }
}