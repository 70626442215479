<template>
    <div class="loading-bar" v-show="show">
        <div class="inner" :style="styles"></div>
    </div>
</template>

<script>
    // https://codesandbox.io/s/5kj3kklvp4
    // https://forum.vuejs.org/t/creating-custom-loading-bar-before-each-route/31378/11
    import {
        mapGetters
    } from "vuex";

    let _loadingInterval = 0;

    export default {
        data() {
            return {
                show: false,
                status: "success",
                percent: 0,
                color: "#2d8cf0",
                failedColor: "#ed3f14"
            };
        },

        computed: {
            ...mapGetters([
                'getLoading'
            ]),

            styles() {
                let style = {
                    width: `${this.percent}%`
                };
                if (this.status === "success") {
                    style.backgroundColor = this.color;
                }
                if (this.status === "error") {
                    style.backgroundColor = this.failedColor;
                }
                return style;
            }
        },

        methods: {
            showProgress(show) {
                this.show = show;
            },
            start() {
                this.showProgress(true);
                this.percent = 0;

                _loadingInterval = setInterval(() => {
                    this.percent = this.percent + 25;
                }, 250)
            },
            stop() {
                clearInterval(_loadingInterval);
                this.percent = 100;

                setTimeout(() => {
                    this.showProgress(false);
                }, 250);
            }
        },

        watch: {
            getLoading: function (val) {
                if (val) {
                    this.start();
                } else {
                    this.stop();
                }
            }
        }
    };
</script>

<style lang="css">
    .loading-bar {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 10000;
    }

    .loading-bar .inner {
        transition: width 0.2s linear;
        height: 5px;
    }
</style>