var axios = require("axios");

export default {
    namespaced: true,
    state: {
        operatorList: []
    },
    mutations: {
        setOperatorList(state, operatorList) {
            state.operatorList = operatorList
        },
        clearData(state) {
            state.operatorList = []
        }
    },
    actions: {
        initOperatorList: async function ({
            commit,
            state,
            rootState
        }) {
            if (state.operatorList.length == 0) {
                try {
                    var resp = await axios.get(process.env.VUE_APP_API_URL + '/user/', {
                        params: {
                            page: 1,
                            size: 250
                        }
                    })

                    let user = resp.data.content
                        .filter(e => e.securityRoleIds.includes("bippotalk_admin") || e.securityRoleIds.includes("messages_admin"))
                        .map(user => {
                            // check active user
                            if(rootState.socket.activeOperatorIds.indexOf(user._id) != -1) {
                                user.active = true
                            } else {
                                user.active = false
                            }
                            return user
                        })
                    
                    // sort operator active to top
                    user.sort((a,b) => b.active - a.active)
                    
                    commit('setOperatorList', user)
                } catch (error) {
                    this.alertMessage = this.$helpers.createError(error)
                }
            }
        }
    }
}