<template>
  <div>
    <LoadingBar />
    <component :is="layout">
      <router-view :layout.sync="layout" />
    </component>
  </div>
  <!--div id="app">
    
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/login">Login</router-link>
    </div>
    
  </div-->
</template>
<script>
  import 'bootstrap/dist/css/bootstrap.css'
  import 'bootstrap-vue/dist/bootstrap-vue.css'
  import LoadingBar from "./components/LoadingBar.vue"


  export default {
    name: 'App',
    data() {
      return {
        layout: 'span',
      };
    },
    components: {
      LoadingBar
    }
  }
</script>